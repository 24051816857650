import { gql } from "@apollo/client"

export const GET_FACTORY = gql`
  query Factory($factory: String!) {
    factory(factory: $factory) {
      partNo
      factory
      process
      quantity
      minStroke
      needMaintenance
    }
  }
`

export const GET_PM_HISTORY = gql`
  query PmHistory($data: partNo!) {
    pmHistory(data: $data) {
      testScanner {
        partNo
        process
        quantity
      }
      maintenance {
        date
        partNo
        process
      }
    }
  }
`
export const GET_PM_SHEET = gql`
  query GetPMHistorySheet($year: String) {
    getPMHistorySheet(year: $year) {
      month
      count
    }
  }
`

export const GET_PROCESS_ORDER = gql`
  query ProcessPart($partNo: String!) {
    processPart(partNo: $partNo) {
      processOrder
    }
  }
`

export const GET_INFORMANT_PM = gql`
  query GetPMHistory($date: String, $factory: String) {
    getPMHistory(date: $date, factory: $factory) {
      repairRequestDate
      repairRequestTime
      documentNo
      line
      factory
      partNo
      partName
      process
      customer
      worker
      leaderProduction
      machine
      objective
      type
      production
      repairDueDate
      problem
      repairDate
      repairTime
      repairer
      repairSendBack
      checkAndEdit
      purchaseNo
      purchaseDate
      receiveDate
      quantity
      amount
      startRepairDate
      startRepairTime
      endRepairDate
      endRepairTime
      recipient
      code
      cause
      howToRepair
      summaryRepair
      NGQuantity
      deliverDate
      deliverLeaderProduction
      leaderQC
      leaderTooling
      leaderToolingDate
      qcDate
      summaryQC
      repairDueDateTime
      dieType
      manHour
      machineHour
      holiday
    }
  }
`

export const GET_MAN_MACHINE_PM = gql`
  query GetBreakDownPMHistory(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getBreakDownPMHistory(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      manHour
      machineHour
      machine
      partNo
    }
  }
`

export const GET_PROBLEM_PM = gql`
  query GetDefectParetoProblemPMHistory(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getDefectParetoProblemPMHistory(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      grouped_problem
      problem_count
      factory
    }
  }
`
export const GET_PROBLEM_PM_STACK = gql`
  query GetDefectParetoProblemPMHistoryStack(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getDefectParetoProblemPMHistoryStack(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      grouped_problem
      problem_count
      factory
    }
  }
`

export const GET_OBJECTIVE_CREATE = gql`
  query GetDefectParetoProblemCreate(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getDefectParetoProblemCreate(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      grouped_problem
      problem_count
      factory
    }
  }
`

export const GET_LOG_INFORMANT_PM = gql`
  query GetLogPMHistory($dateFrom: String, $dateTo: String, $factory: String) {
    getLogPMHistory(dateFrom: $dateFrom, dateTo: $dateTo, factory: $factory) {
      repairRequestDate
      repairRequestTime
      documentNo
      line
      factory
      partNo
      partName
      process
      customer
      worker
      leaderProduction
      machine
      objective
      type
      production
      repairDueDate
      problem
      repairDate
      repairTime
      repairer
      repairSendBack
      checkAndEdit
      purchaseNo
      purchaseDate
      receiveDate
      quantity
      amount
      startRepairDate
      startRepairTime
      endRepairDate
      endRepairTime
      recipient
      code
      cause
      howToRepair
      summaryRepair
      NGQuantity
      deliverDate
      deliverLeaderProduction
      leaderQC
      leaderTooling
      leaderToolingDate
      qcDate
      summaryQC
      repairDueDateTime
      dieType
      manHour
      machineHour
    }
  }
`

export const GET_MASTER_PM_SPARE_PART = gql`
  query MasterPMSparePart {
    masterPMSparePart {
      partName
      code
      partNo
      min
      reOrder
      max
    }
  }
`

export const GET_PM_SPARE_PART = gql`
  query GetSparePartStockCard($code: String, $factory: String) {
    getSparePartStockCard(code: $code, factory: $factory) {
      code
      quantity
    }
  }
`

export const GET_PM_SPARE_PART_STOCK_CARD = gql`
  query GetSparePartStockCardByDate(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getSparePartStockCardByDate(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      code
      actionDate
      in
      out
      balance
      beginStock
    }
  }
`
export const GET_MACHINE_PARETO = gql`
  query GetListProblemMachineList(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    getListProblemMachineList(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      machine
      machineCount
    }
  }
`

export const GET_TRIAL_TOOLING_SHEET = gql`
  query GetTrialToolingSheet {
    getTrialToolingSheet {
      trialId
      recordNo
      partName
      partNo
      customer
      factory
      trialDate
      targetTrialEvent
      controlNo
      revisionNo
      cycleTime
      capacity
      internalDefect
      operatorSkill
      cpk
      reworkRatio
      selectedTools
      toolingData
      material
      plating
      trialNo
      quantity
      jobNo
      pJPlanDate
      pJStartTime
      pJEndTime
      pJSignature
      pJSignatureTime
      pJMembersTrial
      pLPlanDate
      pLStartTime
      pLEndTime
      pLSignature
      pLMembersTrial
      eNGSignature
      eNGMembersTrial
      pD1Signature
      pD1MembersTrial
      pD2Signature
      pD2MembersTrial
      pDSignature
      pDMembersTrial
      pTSignature
      pTMembersTrial
      qASignature
      qAMembersTrial
      mTSignature
      mTMembersTrial
      fmSignature
      engineerHead1Signature
      engineerHead2Signature
      fmSignatureTime
      engineerHead1SignatureTime
      engineerHead2SignatureTime
      updatedAt
      selectedDivisions
      commentPj
      commentPl
      fileName
    }
  }
`

export const DOWNLOAD_TRIAL_TOOLING_SUMMERY = gql`
  query DownloadTrialToolingSummery($trialId: Int) {
    downloadTrialToolingSummery(trialId: $trialId) {
      trialId
      url
    }
  }
`

export const GET_ENG_APPOINTMENT_DATA = gql`
  query GetEngAppointmentData {
    getEngAppointmentData {
      appointId
      recordNo
      actionDate
      meetingDate
      startTime
      endTime
      meetingRoom
      content
      approve
      dateApprove
      cancel
      participants
    }
  }
`
