import {
  Badge,
  BadgeProps,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import { useState, useMemo, useEffect, useContext } from "react"
import { GET_NOTIFICATION } from "./Query/GeneralQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import InfiniteScroll from "react-infinite-scroll-component"
import {
  UPDATE_USER_READ,
  UPDATE_USER_READ_ALL
} from "./Mutation/GeneralMutation"

import moment from "moment"
import ArtTrackSharpIcon from "@mui/icons-material/ArtTrackSharp"
import { user } from "./MainApp"
import { Plagiarism } from "@mui/icons-material"
import PostAddIcon from "@mui/icons-material/PostAdd"
import CancelIcon from "@mui/icons-material/Cancel"
import NoteIcon from "@mui/icons-material/Note"
import HandymanIcon from "@mui/icons-material/Handyman"
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined"
import AuthContext from "../context/AuthContext"
import jwt_decode from "jwt-decode"
import { GET_EMPLOYEE } from "./Query/MasterQuery"
import { IEmployee } from "../page/Master/Employee"
import GroupsIcon from "@mui/icons-material/Groups"

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    right: 3,
    bottom: 27
  }
}))

interface IDataNotification {
  actionDate: string
  status: boolean
  message: string
  id?: number
  link: string
  time: string
  userRead?: string
}

interface IDataUnReadNotification {
  notification: [IDataNotification]
  unRead: number
}

interface IUser {
  role: string
  username: string
  position: string
  employee: string
  factory: string
  departmentId: string
}

const notificationLogoList = [
  {
    key: "/ProductionPlanReport",
    icon: <PostAddIcon color="success" />
  },
  { key: "/ProductionOEEResult", icon: <CancelIcon color="error" /> },
  {
    key: "/ApproveEditScanner",
    icon: <Plagiarism style={{ color: "#ffc830" }} />
  },
  {
    key: "/JobOrder",
    icon: <NoteIcon style={{ color: "yellowgreen" }} />
  },
  {
    key: "/RepairDocument",
    icon: <HandymanIcon style={{ color: "red" }} />
  },
  {
    key: "/PMDocument",
    icon: <EngineeringOutlinedIcon style={{ color: "green" }} />
  },
  {
    key: "/TrialToolingSheet",
    icon: <Plagiarism style={{ color: "#ffc830" }} />
  },
  {
    key: "/AppointmentDocument",
    icon: <GroupsIcon style={{ color: "green" }} />
  }
]
const Notification: React.FC<{ width: number }> = (props: {
  width: number
}) => {
  const theme = useTheme()
  const { width } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [count, setCount] = useState<number>(1)
  const [unRead, setUnRead] = useState<number>(0)
  const [notificationData, setNotificationData] =
    useState<IDataUnReadNotification>()
  const { token } = useContext(AuthContext)
  const { data: employees, loading } = useQuery(GET_EMPLOYEE)
  useEffect(() => {
    const wsUrl =
      "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
    const ws = new WebSocket(wsUrl)

    ws.onopen
    ws.onmessage = (event: MessageEvent) => {
      const data: IDataNotification = JSON.parse(event.data)
      setNotificationData({
        notification: notificationData ? notificationData.notification : [data],
        unRead: notificationData ? notificationData.unRead + 1 : 1
      })
    }
  }, [notificationData])

  const users = useMemo(() => {
    if (token && !loading && employees?.employee) {
      const decoded: IUser = jwt_decode(token)
      decoded.departmentId =
        employees?.employee?.find(
          (e: IEmployee) =>
            e.firstName === decoded.username && e.factory === decoded.factory
        )?.departmentId || ""
      return decoded
    } else {
      return {
        role: "",
        username: "",
        position: "",
        employee: "",
        factory: "",
        departmentId: ""
      }
    }
  }, [token, loading])
  const [getData] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only"
  })
  useEffect(() => {
    if (notificationData) {
      setUnRead(notificationData.unRead)
    }
  }, [notificationData])

  const [updateUserRead] = useMutation(UPDATE_USER_READ, {
    refetchQueries: [
      {
        query: GET_NOTIFICATION,
        variables: {
          department: users.role,
          limit: count,
          userId: user.username
        }
      }
    ]
  })
  const [updateUserReadAll] = useMutation(UPDATE_USER_READ_ALL)

  const endScroll = async () => {
    setCount(count + 1)
    const dataScroll = await getData({
      variables: {
        department: users.role,
        limit: 10 * (count + 1),
        userId: user.username
      }
    })
    const dataNotification = dataScroll.data
    setNotificationData(dataNotification?.getNotification)
  }

  const updateStatus = async (data: IDataNotification) => {
    const userRead = data.userRead
      ? data.userRead + "," + user.username
      : user.username
    if (!data.status) {
      await updateUserRead({
        variables: {
          updateUserReadId: data.id,
          userRead: userRead
        }
      })
    }
    handleClose()
  }

  const updateReadAll = async () => {
    await updateUserReadAll({
      variables: {
        userRead: user.username
      }
    })
  }

  const handleClickNotification = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const openIcon = Boolean(anchorEl)

  useMemo(async () => {
    if (!anchorEl) {
      if (notificationData) {
        const dataNotification = await getData({
          variables: {
            department: users.role,
            limit: 10,
            userId: user.username
          }
        })
        if (dataNotification) {
          setNotificationData(dataNotification?.data?.getNotification)
        }
      } else {
        const dataNotification = await getData({
          variables: {
            department: users.role,
            limit: 10,
            userId: user.username
          }
        })
        setNotificationData(dataNotification?.data?.getNotification || [])
      }
    }
  }, [users])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getPath = (str: string, link: string, actionDate: string) => {
    if (link === "/RepairDocument") {
      const department = getString(str, 2)
      const no = getString(str, 9)
      const factory = getString(str, 10)
      const date = getString(str, 1)
      if (department && no && date) {
        return `?department=${department}&no=${no}&date=${date}&factory=${factory}#${link}`
      }
    }
    if (link === "/JobOrder") {
      const jobOrder = getString(str, 2)
      const partNo = getString(str, 4)
      if (jobOrder && partNo) {
        return `?job=${jobOrder}&partNo=${partNo}#${link}`
      }
    }
    if (link === "/ApproveEditScanner") {
      return `?date=${actionDate}#${link}`
    }
    if (link === "/ProductionOEEResult") {
      const date = getString(str, 2)
      const factory = getString(str, 1)
      const line = getString(str, 3)
      if (date && factory && line) {
        return `?date=${date}&factory=${factory}&line=${line}#${link}`
      }
    }
    if (link === "/ProductionPlanReport") {
      const line = getString(str, 1)
      const rev = getString(str, 2)
      const factory = getString(str, 3)
      const date = getString(str, 4)
      const shift = getString(str, 5)
      if (line && rev && factory && date) {
        return `?factory=${factory}&revision=${rev}&date=${date}&line=${line}&shift=${shift}#${link}`
      }
    }
    if (link === "/PMDocument") {
      const date = getString(str, 1)
      const factory = getString(str, 3)
      const documentNo = getString(str, 2)
      if (date && factory && documentNo) {
        return `?date=${date}&factory=${factory}&documentNo=${documentNo}#${link}`
      }
    }
    return `#${link}`
  }

  const getString = (str: string, num: number) => {
    return str.trim()?.split("\n")[num]?.trim()?.split(":")[1]?.trim()
  }

  return (
    <>
      <Tooltip title="Notifications" key={"notification-key"}>
        <Button
          onClick={handleClickNotification}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openIcon ? "notifications-list" : undefined}
          aria-haspopup="true"
          aria-expanded={openIcon ? "true" : undefined}
        >
          <StyledBadge
            badgeContent={unRead}
            color="error"
            max={999}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <NotificationsNoneOutlinedIcon
              fontSize={width > 600 ? "large" : "small"}
              style={{ color: theme.palette.text.primary }}
            />
          </StyledBadge>
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px", minWidth: "450px" }}
        style={{ borderRadius: "50px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid container spacing={0} direction="column">
          <Grid container item xs={12} sx={{ paddingLeft: "15px" }}>
            <Grid item>
              <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
                การแจ้งเตือน
              </Typography>
            </Grid>
            <Grid item alignItems={"right"}>
              <Button style={{ marginLeft: "170px" }} onClick={updateReadAll}>
                อ่านทั้งหมด
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={0}>
            <div
              id="scrollableDiv"
              style={{ height: 350, overflow: "auto", maxWidth: "440px" }}
            >
              <InfiniteScroll
                dataLength={
                  notificationData ? notificationData?.notification?.length : 0
                }
                next={endScroll}
                hasMore={true}
                loader={<></>}
                scrollableTarget="scrollableDiv"
              >
                {notificationData?.notification?.map((element, i) => {
                  return (
                    <div key={i}>
                      <Link
                        href={getPath(
                          element.message,
                          element.link,
                          element.actionDate
                        )}
                        underline="none"
                        style={{ color: "black" }}
                        onClick={() => updateStatus(element)}
                      >
                        <MenuItem key={element.id} style={{}}>
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={1.2}>
                              {
                                notificationLogoList.find(
                                  (logo) => logo.key === element.link
                                )?.icon
                              }
                            </Grid>
                            <Grid item xs={10.8}>
                              <Tooltip title={element.message}>
                                <div
                                  style={{
                                    whiteSpace: "initial",
                                    fontSize: ".9375rem"
                                  }}
                                >
                                  <div
                                    style={
                                      element.status
                                        ? { color: "#65676b" }
                                        : { fontWeight: "bold" }
                                    }
                                  >
                                    {element?.message &&
                                      element.message.slice(0, 67) + " ...."}
                                  </div>
                                  <div
                                    style={
                                      element.status
                                        ? { color: "#65676b" }
                                        : {
                                            color: "#267ef3",
                                            fontWeight: "bold"
                                          }
                                    }
                                  >
                                    {moment(element.actionDate).format(
                                      "DD-MM-YYYY"
                                    ) +
                                      " " +
                                      element.time}
                                    {element.status ? (
                                      ""
                                    ) : (
                                      <ArtTrackSharpIcon
                                        style={{ position: "absolute" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      </Link>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}

export default Notification
