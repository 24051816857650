import { useMutation, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Grid,
  MenuItem,
  TextField,
  useTheme
} from "@mui/material"
import moment from "moment"
import { Dispatch, SetStateAction, useState } from "react"

import swal from "sweetalert"

import { LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { ENG_APPOINTMENT_CREATE } from "../../../common/Mutation/engineerMutation"
import { GET_ENG_APPOINTMENT_DATA } from "../../../common/Query/engineerQuery"
import { GET_DEPARTMENT, GET_EMPLOYEE } from "../../../common/Query/MasterQuery"
import { IDepartment } from "../../Maintenance/RepairDocument"

interface IProp {
  setOpenDialog: Dispatch<SetStateAction<boolean>>
}

interface IDataEmployee {
  employeeId?: number
  firstName?: string
  lastName?: string
  departmentId?: number
  factory?: string
  position?: string
}

const AppointmentFormCreate: React.FC<IProp> = (props: IProp) => {
  const { setOpenDialog } = props
  const theme = useTheme()
  const [actionDate] = useState<string>(moment(new Date()).format("YYYY-MM-DD"))
  const [meetingDate, setMeetingDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [startTime, setStartTime] = useState(moment("07:00", "HH:mm"))
  const [endTime, setEndTime] = useState(moment("08:00", "HH:mm"))
  const [meetingRoom, setMeetingRoom] = useState<string>("")
  const [content, setContent] = useState<string>("")
  const [participants, setParticipants] = useState<IDataEmployee[]>([])
  const [error, setError] = useState<boolean>(false)

  const getDepartment = useQuery(GET_DEPARTMENT)

  let employeeList: IDataEmployee[] = []
  const personsToFilter: IDataEmployee[] = [
    { employeeId: 231, firstName: "วาสนา", departmentId: 4 },
    { employeeId: 317, firstName: "นริทร", departmentId: 7 },
    { employeeId: 686, firstName: "พิทยา", departmentId: 7 },
    { employeeId: 682, firstName: "กฤษณะ", departmentId: 19 },
    { employeeId: 683, firstName: "อนุสรณ์", departmentId: 19 },
    { employeeId: 456, firstName: "มนตรี", departmentId: 3 },
    { employeeId: 286, firstName: "ณัฏฐ์", departmentId: 3 },
    { employeeId: 285, firstName: "ไพทูล", departmentId: 4 },
    { employeeId: 285, firstName: "ไพทูล", departmentId: 4 },
    { employeeId: 156, firstName: "อนุชา(All)", departmentId: 4 },
    { employeeId: 19, firstName: "ไชยศ", departmentId: 6 },
    { employeeId: 316, firstName: "เสน่ห์", departmentId: 6 }
  ]

  const getEmployee = useQuery(GET_EMPLOYEE)
  if (getEmployee.data) {
    employeeList = getEmployee.data.employee.filter((dbPerson: IDataEmployee) =>
      personsToFilter.some(
        (person: IDataEmployee) => dbPerson.employeeId === person.employeeId
      )
    )
  }

  const [engAppointmentCreate, { loading: loadingWhileSubmit }] = useMutation(
    ENG_APPOINTMENT_CREATE,
    {
      refetchQueries: [GET_ENG_APPOINTMENT_DATA],
      awaitRefetchQueries: true
    }
  )

  const handleSubmit = async () => {
    if (!meetingRoom || !content) {
      setError(true)
      return
    }

    const data = {
      actionDate: actionDate,
      meetingDate: meetingDate,
      startTime: moment(startTime).format("HH:mm"),
      endTime: moment(endTime).format("HH:mm"),
      meetingRoom: meetingRoom,
      content: content,
      participants: JSON.stringify(participants)
    }

    try {
      await engAppointmentCreate({
        variables: {
          inputAppointmentCreate: data
        }
      })
      swal("Success", "อัพโหลดข้อมูลสำเร็จ", "success").then(() => {
        setOpenDialog(false)
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const getDepartmentName = (
    departmentId: number | undefined
  ): string | undefined => {
    return getDepartment.data.department.find(
      (dep: IDepartment) => dep.departmentId === departmentId
    )?.departmentName
  }

  return (
    <Grid container spacing={2} width={"98%"} margin={2}>
      <Grid item sm={6} xs={6}>
        <TextField
          type="date"
          value={actionDate}
          fullWidth
          label="วันที่สร้าง"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <TextField
          type="date"
          value={meetingDate}
          fullWidth
          label="วันที่จะประชุม"
          onChange={(e) => setMeetingDate(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            label="เวลาเริ่ม"
            ampm={false}
            views={["hours", "minutes"]}
            inputFormat="HH:mm"
            value={startTime}
            onChange={(newSelectTime) => {
              setStartTime(
                newSelectTime ? newSelectTime : moment("00:00", "HH:mm")
              )
            }}
            renderInput={(params) => (
              <FormTextField
                label="start"
                sx={{
                  input: { textAlign: "center" },

                  "& svg": {
                    color: "#888888"
                  }
                }}
                {...params}
                id="start-time-ot"
                variant="outlined"
              />
            )}
          />
          {" ถึง "}
          <TimePicker
            ampm={false}
            label="เวลาสิ้นสุด"
            views={["hours", "minutes"]}
            inputFormat="HH:mm"
            value={endTime}
            onChange={(newSelectTime) => {
              setEndTime(
                newSelectTime ? newSelectTime : moment("00:00", "HH:mm")
              )
            }}
            renderInput={(params) => (
              <FormTextField
                label="End"
                sx={{
                  input: { textAlign: "center" },

                  "& svg": {
                    color: "#888888"
                  }
                }}
                {...params}
                id="start-time-ot"
                variant="outlined"
              />
            )}
          />
          {" น. "}
        </LocalizationProvider>
      </Grid>
      <Grid item sm={6} xs={6}>
        <TextField
          error={!meetingRoom && error}
          id="meetingRoom"
          fullWidth
          label="สถานที่"
          value={meetingRoom}
          onChange={(e) => setMeetingRoom(e.target.value)}
          select
        >
          <MenuItem value={"meetingRoom1BAE2"}>ห้องประชุม 1 BAE:2</MenuItem>
          <MenuItem value={"meetingRoom2BAE2"}>ห้องประชุม 2 BAE:2</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="employee-autocomplete"
          options={employeeList}
          getOptionLabel={(option) => {
            const departmentName = getDepartmentName(option.departmentId)
            return `${option.firstName}/${
              departmentName || "Unknown Department"
            }`
          }}
          value={participants}
          onChange={(event, newValue) => {
            setParticipants(newValue)
          }}
          renderInput={(params) => (
            <TextField
              label="เลือกผู้เข้าร่วมประชุม"
              {...params}
              variant="outlined"
              sx={{
                input: {
                  color: "black"
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ThemedTextField
          error={!content && error}
          multiline
          fullWidth
          rows={10}
          value={content}
          label="content"
          id="content"
          type="text"
          onChange={(e) => setContent(e.target.value)}
        />
      </Grid>
      <Grid item>
        <ThemedLoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={loadingWhileSubmit}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          create
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}

export default AppointmentFormCreate
