import { useMutation, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Radio,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { useEffect, useState } from "react"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import swal from "sweetalert"

import { LocalizationProvider, TimePicker } from "@mui/lab"
import DatePicker from "@mui/lab/DatePicker"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import FormTextField from "../../common/Resources/ThemedComponents/Maintenance/FormTextField"

import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTabs from "../../common/Resources/ThemedComponents/ThemedTabs"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import React from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import AppointmentFormCreate from "./Component/AppointmentFormCreate"

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

interface ICodeList {
  recordNo: string
  actionDate: string
  documentNo: string
  approveBy: string
  cancel: boolean
}

interface IEngAppointData {
  appointId: number
  recordNo: number
  actionDate: string
  meetingDate: string
  startTime: string
  endTime: string
  meetingRoom: string
  content: string
  approve: string
  dateApprove: string
  cancel: boolean
  participants: string
}

interface IEngAppointParticipants {
  recordNo: number
  actionDate: string
  employeeId: number
  firstName: string
  lastName: string
  departmentId: number
  joinAble: boolean | number | undefined
  sign: string
  representative: string | null
}

interface INextStepProcessEndAdornment {
  endAdornment: React.ReactNode
  status: string | undefined
  cancel: boolean | undefined
  recordNo: string
}

const NextStepProcessEndAdornment = (props: INextStepProcessEndAdornment) => {
  const { endAdornment, status, cancel, recordNo } = props
  const children = React.Children.toArray(
    (endAdornment as React.ReactElement)?.props?.children
  )
  recordNo
    ? children.unshift(
        status ? (
          <CheckCircleIcon
            key={`check-circle-icon-${recordNo}`}
            sx={{
              color: "green"
            }}
          />
        ) : cancel ? (
          "ใบนัดถูกยกเลิกแล้ว"
        ) : (
          <CancelIcon
            key={`cancel-icon-${recordNo}`}
            sx={{
              color: "red"
            }}
          />
        )
      )
    : null
  return React.isValidElement(endAdornment)
    ? React.cloneElement(endAdornment, {}, children)
    : null
}

const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  padding: "5px",
  fontSize: "22px",
  border: "solid 1px #ffffff",
  backgroundColor: "#FFFFFF",
  color: "#000000"
}))
const StyledTableCellBlackBorder = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  padding: "5px",
  fontSize: "22px",
  border: "solid 1px black",
  backgroundColor: "#FFFFFF",
  color: "#000000"
}))
import { clearParam, formatDateToThai, miniLogo } from "../../common/utilities"
import { makeStyles } from "@mui/styles"
import { GET_ENG_APPOINTMENT_DATA } from "../../common/Query/engineerQuery"
import {
  ENG_APPOINTMENT_DELETE,
  ENG_APPOINTMENT_DOC_APPROVE,
  ENG_APPOINTMENT_SIGN_NAME,
  ENG_APPOINTMENT_UPDATE
} from "../../common/Mutation/engineerMutation"
import { user } from "../../common/MainApp"
import { GET_DEPARTMENT, GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import { IDepartment } from "../Maintenance/RepairDocument"
import { checkRole } from "../../config/routes"

interface IProp {
  filteredData?: IEngAppointData
}

const pageTitle = "Appointment Document"

const AppointmentDocument: React.FC = () => {
  const theme = useTheme()
  const [tapMenu, setTapMenu] = useState<number>(0)
  const [recordNo, setRecord] = useState<string>("4")
  const [circular, setCircular] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const [filteredData, setFilteredData] = useState<IEngAppointData>()

  const { data: getEngAppointmentData } = useQuery(GET_ENG_APPOINTMENT_DATA)

  let codeList = [] as ICodeList[]

  if (getEngAppointmentData && getEngAppointmentData.getEngAppointmentData) {
    codeList = getEngAppointmentData.getEngAppointmentData.filter(
      (data: { actionDate: string }) =>
        moment(selectDate).format("YYYY-MM") ===
        moment(data.actionDate).format("YYYY-MM")
    )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }
  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.secondary.contrastText
      },
      "& .MuiTab-root.Mui-selected": {
        color: theme.palette.secondary.contrastText
      }
    }
  })

  useEffect(() => {
    setRecord("")
  }, [selectDate])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const selectDateParam = urlParams.get("selectDate")
  const recordNoParam = urlParams.get("recordNo")
  const panelParam = urlParams.get("panel")
  const panelInt = panelParam ? parseInt(panelParam, 10) : 0

  useEffect(() => {
    if (
      getEngAppointmentData &&
      selectDateParam &&
      recordNoParam &&
      panelParam
    ) {
      setSelectDate(
        selectDateParam ? moment(selectDateParam).toDate() : new Date()
      )
      setRecord(recordNoParam)
      setTapMenu(panelInt)

      const filterInfo = getEngAppointmentData.getEngAppointmentData.filter(
        (data: IEngAppointData) =>
          data.actionDate === selectDateParam &&
          data.recordNo === parseInt(recordNoParam, 10)
      )
      if (filterInfo.length < 1) {
        swal("Error", "ไม่พบข้อมูล", "warning")
      } else {
        setFilteredData(
          filterInfo && filterInfo.length > 0 ? filterInfo[0] : []
        )
        swal("Success", "Load ข้อมูลเรียบร้อย", "success")
      }
      clearParam("AppointmentDocument")
    }
  }, [
    getEngAppointmentData,
    selectDateParam,
    recordNoParam,
    panelParam,
    panelInt
  ])

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <Dialog open={circular} onClose={() => setCircular(false)}>
          <DialogContent style={{ width: "auto" }}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <PageLabelCard
          title="Appointment"
          subTitle="หน้า Page สำหรับ Project Engineer ใช้สร้าง ตรวจสอบ Confirm ใบนัดประชุม "
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Box sx={{ width: "100%" }}>
            <div className="hidden-print">
              <Grid container spacing={2}>
                <Grid item sm={2} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Select Month"
                      views={["year", "month"]}
                      value={selectDate}
                      onChange={(newSelectDate) => {
                        setSelectDate(moment(newSelectDate).toDate() as Date)
                      }}
                      PaperProps={{
                        sx: {
                          "& .MuiCalendarPicker-root": {
                            ".Mui-selected": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.warning.main
                                  : theme.palette.primary.main,
                              color: "#000000"
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          fullWidth
                          sx={{
                            "& .Mui-selected": {
                              backgroundColor: theme.palette.warning.main
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    fullWidth
                    id="next-step-process"
                    disablePortal
                    value={
                      codeList.find(
                        (val) =>
                          `(No. ${val.recordNo}) Date ${val.actionDate}` ===
                          recordNo
                      ) || null
                    }
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label={"Document No."}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <NextStepProcessEndAdornment
                              endAdornment={params.InputProps.endAdornment}
                              status={
                                codeList.find(
                                  (val) =>
                                    `(No. ${val.recordNo}) Date ${val.actionDate}` ===
                                    recordNo
                                )?.approveBy
                              }
                              cancel={
                                codeList.find(
                                  (val) =>
                                    `(No. ${val.recordNo}) Date ${val.actionDate}` ===
                                    recordNo
                                )?.cancel
                              }
                              recordNo={recordNo}
                            />
                          )
                        }}
                      />
                    )}
                    onChange={(e, newValue) => {
                      const filterData =
                        getEngAppointmentData.getEngAppointmentData.filter(
                          (data: { actionDate: string; recordNo: string }) => {
                            return (
                              data.recordNo === newValue?.recordNo &&
                              data.actionDate === newValue?.actionDate
                            )
                          }
                        )
                      setFilteredData(filterData[0])
                      setTapMenu(1)

                      setRecord(
                        newValue?.recordNo
                          ? `(No. ${newValue.recordNo}) Date ${newValue.actionDate}`
                          : ""
                      )
                    }}
                    getOptionLabel={(option) =>
                      option?.recordNo
                        ? `(No. ${option.recordNo}) Date ${option.actionDate}`
                        : ""
                    }
                    renderOption={(props, options) => {
                      return (
                        <Box component="li" {...props}>
                          <Grid container sx={{ padding: "0 5px" }}>
                            <Grid item xs="auto">
                              {`(No. ${options.recordNo}) Date ${options.actionDate}`}
                            </Grid>
                            <Grid item container xs justifyContent="flex-end">
                              {options.approveBy ? (
                                <CheckCircleIcon
                                  sx={{
                                    color: "green"
                                  }}
                                />
                              ) : options.cancel ? (
                                "ใบนัดถูกยกเลิกแล้ว"
                              ) : (
                                <CancelIcon
                                  sx={{
                                    color: "red"
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    }}
                    options={codeList}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <ThemedLoadingButton
                    variant="contained"
                    sx={{ mt: 1, width: "150px", marginLeft: "10px" }}
                    style={{ backgroundColor: theme.palette.warning.main }}
                    onClick={() => setOpenDialog(true)}
                  >
                    CREATE
                  </ThemedLoadingButton>
                </Grid>
              </Grid>
              <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="lg"
                fullWidth
              >
                <DialogTitle style={{ textAlign: "center" }}>
                  สร้างใบนัดประชุม
                </DialogTitle>
                <DialogContent>
                  <AppointmentFormCreate setOpenDialog={setOpenDialog} />
                </DialogContent>
              </Dialog>
            </div>

            <ThemedTabs
              value={tapMenu}
              onChange={handleChange}
              className={classes.tabs}
              aria-label="basic tabs example"
            >
              <Tab label="แก้ไขใบนัด" {...tapProps(0)} />
              <Tab label="รายละเอียด" {...tapProps(1)} />
              <Tab
                label="Upload"
                {...tapProps(2)}
                style={filteredData?.cancel !== true ? {} : { display: "none" }}
              />
            </ThemedTabs>

            <TabPanel value={tapMenu} index={0}>
              <br />

              {filteredData ? (
                <AppointmentEdit filteredData={filteredData} />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={1}>
              <br />

              {filteredData ? (
                <AppointmentDetail filteredData={filteredData} />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={2}>
              <br />

              {filteredData ? <AppointmentSummary /> : <div></div>}
            </TabPanel>
          </Box>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

const AppointmentEdit: React.FC<IProp> = (props: IProp) => {
  const theme = useTheme()
  const { filteredData } = props

  useEffect(() => {
    setMeetingDate(
      moment(filteredData?.meetingDate || new Date()).format("YYYY-MM-DD")
    )
    setStartTime(
      filteredData?.startTime
        ? parseTimeStringToDate(filteredData?.startTime)
        : null
    )
    setEndTime(
      filteredData?.endTime
        ? parseTimeStringToDate(filteredData?.endTime)
        : null
    )

    setMeetingRoom(filteredData?.meetingRoom || "")
    setContent(filteredData?.content || "")
  }, [filteredData])

  const parseTimeStringToDate = (timeString?: string): Date | null => {
    if (!timeString) return null
    const [hours, minutes] = timeString.split(":").map(Number)
    const date = new Date()
    date.setHours(hours, minutes, 0, 0)
    return date
  }

  const [meetingDate, setMeetingDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [startTime, setStartTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.startTime)
  )
  const [endTime, setEndTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.endTime)
  )
  const [meetingRoom, setMeetingRoom] = useState<string>("")
  const [content, setContent] = useState<string>("")

  const [updateAppointment, { loading: loadingWhileUpdate }] = useMutation(
    ENG_APPOINTMENT_UPDATE,
    {
      refetchQueries: [GET_ENG_APPOINTMENT_DATA],
      awaitRefetchQueries: true
    }
  )

  const handleUpdate = async () => {
    if (filteredData?.approve) {
      swal(
        "info",
        "Update ไม่ได้ เพราะ Manager Engineer Approve เอกสารแล้ว",
        "Info"
      )
      return
    }
    if (filteredData?.cancel === true) {
      swal("info", "Update ไม่ได้ เพราะรายการนี้ถูกยกเลิกแล้ว", "Info")
      return
    }

    const data = {
      appointId: filteredData?.appointId,
      meetingDate: meetingDate,
      startTime: moment(startTime).format("HH:mm"),
      endTime: moment(endTime).format("HH:mm"),
      meetingRoom: meetingRoom,
      content: content
    }

    try {
      await updateAppointment({
        variables: { inputAppointmentUpdate: data }
      })
      swal("Success", "Update Success", "success").then(() => {
        window.location.reload()
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const [deleteAppointment, { loading: loadingWhileDelete }] = useMutation(
    ENG_APPOINTMENT_DELETE,
    {
      refetchQueries: [GET_ENG_APPOINTMENT_DATA],
      awaitRefetchQueries: true
    }
  )

  const handleDelete = async () => {
    const data = {
      appointId: filteredData?.appointId,
      cancel: true
    }

    const isConfirmed = await swal({
      title: "ต้องการลบรายการนี้ใช่หรือไม่?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
    if (isConfirmed) {
      try {
        await deleteAppointment({
          variables: { inputAppointmentDelete: data }
        })
        swal("Success", "Delete Success", "success").then(() => {
          window.location.reload()
        })
      } catch (error) {
        swal("Error", `${(error as Error).message}`, "error")
      }
    } else return
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "10px", backgroundColor: "white" }}>
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={miniLogo}
                    alt="Brother Auto Parts."
                    width={"150px"}
                  />
                  <Typography>
                    หนังสือเชิญประชุม
                    <br />
                    คณะกรรมการ APQP TEAM
                  </Typography>
                </Box>
              </StyledTableCell>
              <StyledTableCell colSpan={1}>
                {`MAOQO Doc No.${filteredData?.recordNo} ${filteredData?.actionDate}`}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <Divider
                  sx={{
                    height: 5,
                    bgcolor: "black"
                  }}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Grid container>
                  เรียน คณะกรรมการ APQP TEAM <br />
                  เรื่อง : เชิญประชุมคณะกรรมการ APQP TEAM
                </Grid>
              </StyledTableCell>
              <StyledTableCell>{`วันที่ ${formatDateToThai(
                filteredData?.actionDate
              )}`}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {`กำหนดการประชุม ครั้งที่ ${
                    filteredData?.recordNo
                  }/${new Date(filteredData?.actionDate || "").getFullYear()}`}
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ textAlign: "left" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  ขอเรียนเชิญ APQP TEAM ทุกท่านตามกำหนดการดังนี้
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", pl: "50px" }}>
                  <Typography>วันที่ : </Typography>
                  <TextField
                    type="date"
                    value={meetingDate}
                    onChange={(e) => setMeetingDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", pl: "50px" }}>
                  <Typography>เวลา : </Typography>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      ampm={false}
                      views={["hours", "minutes"]}
                      inputFormat="HH:mm"
                      value={startTime}
                      onChange={(newSelectTime) => {
                        setStartTime(newSelectTime ? newSelectTime : null)
                      }}
                      renderInput={(params) => (
                        <FormTextField
                          label="start"
                          sx={{
                            input: { textAlign: "center" },

                            "& svg": {
                              color: "#888888"
                            }
                          }}
                          {...params}
                          id="start-time-ot"
                          variant="outlined"
                        />
                      )}
                    />
                    {" ถึง "}
                    <TimePicker
                      ampm={false}
                      views={["hours", "minutes"]}
                      inputFormat="HH:mm"
                      value={endTime}
                      onChange={(newSelectTime) => {
                        setEndTime(newSelectTime ? newSelectTime : null)
                      }}
                      renderInput={(params) => (
                        <FormTextField
                          label="End"
                          sx={{
                            input: { textAlign: "center" },

                            "& svg": {
                              color: "#888888"
                            }
                          }}
                          {...params}
                          id="start-time-ot"
                          variant="outlined"
                        />
                      )}
                    />
                    {" น. "}
                  </LocalizationProvider>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", pl: "50px" }}>
                  <Typography>สถานที่ : </Typography>

                  <TextField
                    id="meetingRoom"
                    value={meetingRoom}
                    sx={{ width: "50%" }}
                    onChange={(e) => setMeetingRoom(e.target.value)}
                    select
                  >
                    <MenuItem value={"meetingRoom1BAE2"}>
                      ห้องประชุม 1 BAE:2
                    </MenuItem>
                    <MenuItem value={"meetingRoom2BAE2"}>
                      ห้องประชุม 2 BAE:2
                    </MenuItem>
                  </TextField>
                </Box>

                <Typography sx={{ fontWeight: "bold" }}>
                  วาระการประชุม
                </Typography>
                <ThemedTextField
                  multiline
                  fullWidth
                  rows={10}
                  value={content}
                  id="content"
                  type="text"
                  onChange={(e) => setContent(e.target.value)}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box pt="100px"></Box>

        <ThemedLoadingButton
          disabled={filteredData?.cancel}
          variant="contained"
          sx={{ mt: 1, width: "150px" }}
          style={{ backgroundColor: theme.palette.primary.light }}
          onClick={handleUpdate}
          loading={loadingWhileUpdate}
        >
          UPDATE
        </ThemedLoadingButton>
        <ThemedLoadingButton
          disabled={filteredData?.cancel}
          variant="contained"
          sx={{ mt: 1, width: "150px" }}
          style={{
            backgroundColor: theme.palette.error.main,
            marginLeft: "10px"
          }}
          onClick={handleDelete}
          loading={loadingWhileDelete}
        >
          DELETE
        </ThemedLoadingButton>
      </div>
    </ThemeProvider>
  )
}

const AppointmentDetail: React.FC<IProp> = (props: IProp) => {
  const theme = useTheme()
  const { filteredData } = props

  useEffect(() => {
    setMeetingDate(
      moment(filteredData?.meetingDate || new Date()).format("YYYY-MM-DD")
    )

    setStartTime(filteredData?.startTime ? filteredData?.startTime : undefined)
    setEndTime(filteredData?.endTime ? filteredData?.endTime : undefined)

    setMeetingRoom(filteredData?.meetingRoom || "")
    setContent(filteredData?.content || "")
    setEngineerApprover(filteredData?.approve || "")
    setDateApprove(filteredData?.dateApprove || "")
    setParticipants(
      JSON.parse(filteredData?.participants ? filteredData?.participants : "")
    )
  }, [filteredData])

  const [meetingDate, setMeetingDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [startTime, setStartTime] = useState<string | undefined>("")
  const [endTime, setEndTime] = useState<string | undefined>("")

  const [meetingRoom, setMeetingRoom] = useState<string>("")
  const [content, setContent] = useState<string>("")
  const [engineerApprover, setEngineerApprover] = useState<string>("")
  const [dateApprove, setDateApprove] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [participants, setParticipants] = useState<IEngAppointParticipants[]>(
    []
  )

  const getEmployee = useQuery(GET_EMPLOYEE)

  const getDepartment = useQuery(GET_DEPARTMENT)

  const getDepartmentName = (departmentId: number): string => {
    return getDepartment?.data?.department.find((e: IDepartment) => {
      return e.departmentId === departmentId
    })?.departmentNameThai
  }

  const employeeList =
    Array.from(
      new Set(
        getEmployee.data?.employee.map(
          ({ firstName }: { firstName: string }) => firstName
        )
      )
    ) || []

  const [updateAppointmentApproval, { loading: loadingWhileApprove }] =
    useMutation(ENG_APPOINTMENT_DOC_APPROVE, {
      refetchQueries: [ENG_APPOINTMENT_DOC_APPROVE],
      awaitRefetchQueries: true
    })

  const userRole = {
    engManager: checkRole(["99"]) && checkRole(["7"])
  }

  const employeesList = {
    engManager: ["ภควัต"]
  }

  const handleApprove = async () => {
    if (!engineerApprover) {
      setError(true)
      swal("Error", `กรุณาเลือกชื่อผู้อนุมัติ`, "error")
      return
    }
    const data = {
      appointId: filteredData?.appointId,
      approve: user.username,
      dateApprove: dateApprove
    }

    const isConfirmed = await swal({
      title: `อนุมัติเอกสารนี้โดย: ${user.employee}`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
    if (isConfirmed) {
      try {
        await updateAppointmentApproval({
          variables: { inputAppointmentDocApprove: data }
        })
        swal("Success", "Approve Success", "success").then(() => {
          window.location.reload()
        })
      } catch (error) {
        swal("Error", `${(error as Error).message}`, "error")
      }
    } else return
  }

  const handleJoinAbleChange = (
    index: number,
    canJoin?: boolean,
    person?: string
  ) => {
    const updatedDataDB = [...participants]

    if (canJoin !== undefined) {
      if (canJoin === true) {
        updatedDataDB[index] = {
          ...updatedDataDB[index],
          representative: null
        }
      }
      updatedDataDB[index] = {
        ...updatedDataDB[index],
        joinAble: canJoin
      }
    }
    if (person) {
      updatedDataDB[index] = {
        ...updatedDataDB[index],
        representative: person
      }
    }

    setParticipants(updatedDataDB)
  }

  const [engAppointmentUpdateSignName, { loading: loadingSignName }] =
    useMutation(ENG_APPOINTMENT_SIGN_NAME, {
      refetchQueries: [GET_ENG_APPOINTMENT_DATA],
      awaitRefetchQueries: true
    })

  const handleVerify = async (index: number) => {
    const submitIndexData = [...participants]
    if (
      (submitIndexData[index].joinAble === false ||
        submitIndexData[index].joinAble === 0) &&
      !submitIndexData[index].representative
    ) {
      swal("Error", `ไม่ได้เลือกชื่อผู้เข้าประชุมแทน`, "warning")
      return
    }
    const verifyData = {
      recordNo: filteredData?.recordNo,
      actionDate: filteredData?.actionDate,
      employeeId: submitIndexData[index].employeeId,
      joinAble:
        submitIndexData[index].joinAble === 0 ||
        submitIndexData[index].joinAble === false
          ? false
          : true,
      sign: user.employee,
      representative: submitIndexData[index].representative,
      updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
    }

    const isConfirmed = await swal({
      title: "ต้องการยืนยันใช่หรือไม่?",
      text: `ลงชื่อด้วย ${user.employee}`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
    if (isConfirmed) {
      try {
        await engAppointmentUpdateSignName({
          variables: {
            inputAppointmentSignData: verifyData
          }
        })
        swal("Success", "อัพโหลดข้อมูลสำเร็จ", "success").then(() => {
          window.location.reload()
        })
      } catch (error) {
        swal("Error", `${(error as Error).message}`, "error")
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      {filteredData?.cancel && (
        <Typography
          variant="h2"
          sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}
        >
          ใบนัดนี้ถูกยกเลิกแล้ว
        </Typography>
      )}

      <div style={{ padding: "10px", backgroundColor: "white" }}>
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={miniLogo}
                    alt="Brother Auto Parts."
                    width={"150px"}
                  />
                  <Typography>
                    หนังสือเชิญประชุม
                    <br />
                    คณะกรรมการ APQP TEAM
                  </Typography>
                </Box>
              </StyledTableCell>
              <StyledTableCell colSpan={1}>
                {`MAOQO Doc No.${filteredData?.recordNo} ${filteredData?.actionDate}`}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <Divider
                  sx={{
                    height: 5,
                    bgcolor: "black"
                  }}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Grid container>
                  เรียน คณะกรรมการ APQP TEAM <br />
                  เรื่อง : เชิญประชุมคณะกรรมการ APQP TEAM
                </Grid>
              </StyledTableCell>
              <StyledTableCell>{`วันที่ ${formatDateToThai(
                filteredData?.actionDate
              )}`}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {`กำหนดการประชุม ครั้งที่ ${
                    filteredData?.recordNo
                  }/${new Date(filteredData?.actionDate || "").getFullYear()}`}
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ textAlign: "left" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  ขอเรียนเชิญ APQP TEAM ทุกท่านตามกำหนดการดังนี้
                </Typography>
                <Typography sx={{ pl: "50px" }}>
                  วันที่ : {meetingDate}
                </Typography>
                <Typography sx={{ pl: "50px" }}>
                  {`เวลา : ${startTime} น. - ${endTime} น.`}
                </Typography>
                <Typography sx={{ pl: "50px" }}>
                  {`สถานที่ : ${
                    meetingRoom === "meetingRoom1BAE2"
                      ? "ห้องประชุม 1 BAE:2"
                      : "ห้องประชุม 2 BAE:2"
                  }`}
                </Typography>
                <Typography sx={{ fontWeight: "bold", mt: "20px" }}>
                  วาระการประชุม
                </Typography>
                <ThemedTextField
                  sx={{ pl: "50px" }}
                  multiline
                  fullWidth
                  value={content}
                  id="content"
                  type="text"
                  minRows={3}
                  maxRows={Infinity}
                  variant="standard"
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2} align="right">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: 2
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <Typography>จึงเรียนมาเพื่อทราบ</Typography>
                    <Autocomplete
                      disabled={!userRole.engManager}
                      options={employeesList.engManager}
                      value={engineerApprover}
                      onChange={(event, newValue) => {
                        setEngineerApprover(newValue as string)
                        setDateApprove(moment(new Date()).format("YYYY-MM-DD"))
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={error && !engineerApprover}
                          variant="standard"
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      )}
                    />
                    <Typography>( นายภควัต อเนกวุฒิกร )</Typography>
                    <Typography>{dateApprove}</Typography>
                  </Box>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Box>
        {filteredData?.approve ? (
          <></>
        ) : (
          <ThemedLoadingButton
            disabled={filteredData?.cancel}
            loading={loadingWhileApprove}
            variant="contained"
            sx={{
              fontWeight: 600,
              fontSize: "20px",
              mt: "50px"
            }}
            onClick={handleApprove}
          >
            Approve เอกสาร
          </ThemedLoadingButton>
        )}
      </Box>
      <Box pt="150px"></Box>

      {filteredData?.approve ? (
        <div style={{ padding: "10px", backgroundColor: "white" }}>
          <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={1} sx={{ textAlign: "start" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={miniLogo}
                      alt="Brother Auto Parts."
                      width={"150px"}
                    />
                    <Typography>
                      หนังสือเชิญประชุม
                      <br />
                      คณะกรรมการ APQP TEAM
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={2}>
                  <Divider
                    sx={{
                      height: 5,
                      bgcolor: "black"
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    แบบลงชื่อการเข้าร่วมประชุม
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {`คณะกรรมการ APQP TEAM วันที่ ${formatDateToThai(
                      filteredData?.meetingDate
                    )}`}
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {`เวลา ${filteredData.startTime} น. - ${
                      filteredData.endTime
                    } น. สถานที่ ณ ${
                      meetingRoom === "meetingRoom1BAE2"
                        ? "ห้องประชุม 1 BAE:2"
                        : "ห้องประชุม 2 BAE:2"
                    }`}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
            <TableHead>
              <TableRow>
                <StyledTableCellBlackBorder rowSpan={2}>
                  ลำดับ
                </StyledTableCellBlackBorder>
                <StyledTableCellBlackBorder rowSpan={2}>
                  รายชื่อคณะกรรมการ
                </StyledTableCellBlackBorder>
                <StyledTableCellBlackBorder colSpan={3}>
                  ยืนยันเข้าร่วมประชุม
                </StyledTableCellBlackBorder>

                <StyledTableCellBlackBorder rowSpan={2}>
                  กรณีมาประชุมไม่ได้ระบุ
                  <br />
                  รายชื่อผู้เข้าแทน
                </StyledTableCellBlackBorder>
              </TableRow>
              <TableRow>
                <StyledTableCellBlackBorder>ได้</StyledTableCellBlackBorder>
                <StyledTableCellBlackBorder>ไม่ได้</StyledTableCellBlackBorder>
                <StyledTableCellBlackBorder>ลงชื่อ</StyledTableCellBlackBorder>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    pointerEvents:
                      item.sign || filteredData.cancel
                        ? "none"
                        : user.employeeId === item.employeeId
                        ? "auto"
                        : "none",
                    opacity: user.employeeId === item.employeeId ? 1 : 0.5
                  }}
                >
                  <StyledTableCellBlackBorder align="center">
                    {index + 1}
                  </StyledTableCellBlackBorder>
                  <StyledTableCellBlackBorder align="center">
                    {item.firstName} / {getDepartmentName(item.departmentId)}
                  </StyledTableCellBlackBorder>
                  <StyledTableCellBlackBorder align="center">
                    <Radio
                      checked={item.joinAble === true || item.joinAble === 1}
                      onChange={() =>
                        handleJoinAbleChange(index, true, undefined)
                      }
                    />
                  </StyledTableCellBlackBorder>
                  <StyledTableCellBlackBorder align="center">
                    <Radio
                      checked={item.joinAble === false || item.joinAble === 0}
                      onChange={() =>
                        handleJoinAbleChange(index, false, undefined)
                      }
                    />
                  </StyledTableCellBlackBorder>

                  <StyledTableCellBlackBorder align="center">
                    {item.sign ? (
                      item.sign
                    ) : (
                      <ThemedLoadingButton
                        variant="contained"
                        onClick={() => {
                          handleVerify(index)
                        }}
                        loading={loadingSignName}
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        verify
                      </ThemedLoadingButton>
                    )}
                  </StyledTableCellBlackBorder>
                  <StyledTableCellBlackBorder align="center">
                    <Autocomplete
                      disabled={item.joinAble === true}
                      value={item.representative}
                      fullWidth
                      onChange={(event, selectedRequestBy) => {
                        handleJoinAbleChange(
                          index,
                          undefined,
                          selectedRequestBy as string
                        )
                      }}
                      options={employeeList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            (item.joinAble === false || item.joinAble === 0) &&
                            !item.representative
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  </StyledTableCellBlackBorder>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <></>
      )}
    </ThemeProvider>
  )
}

const AppointmentSummary: React.FC = () => {
  return <>summary</>
}

export default AppointmentDocument
