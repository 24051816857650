import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useEffect, useMemo, useState } from "react"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { PMDocumentGrid } from "./RepairInformant"
import {
  GET_CUSTOMERS,
  GET_EMPLOYEE,
  GET_MACHINES,
  GET_PARTS
} from "../../../common/Query/MasterQuery"
import { useMutation, useQuery } from "@apollo/client"
import { makeCustomerList } from "../../Master/Parts"
import moment from "moment"
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { IMachine } from "../../Master/Machine"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import { autocompleteRenderTags } from "../../../common/Resources/ThemedComponents/Other/autocompleteRenderTags"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { TRIAL_TOOLING_SHEET_UPDATE_APPROVAL } from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { user } from "../../../common/MainApp"

interface ITooling {
  die: string
  size: string
  amount: number
  line: string
  machineCode: string
  [key: string]: number | string
}

const toolNameData: { key: string; name: string }[] = [
  { key: "newTool", name: "แม่พิมพ์ใหม่ NEW TOOLING" },
  { key: "modifyTool", name: "แม่พิมพ์แก้ไข MODIFY TOOLING" },
  { key: "supplyTool", name: "แม่พิมพ์จากลูกค้า SUPPLY TOOLING" },
  { key: "changeMachine", name: "เปลี่ยนเครื่องจักร CHANGE MACHINE" },
  { key: "changeOperator", name: "เปลี่ยนพนักงาน CHANGE OPERATOR" },
  { key: "changeMaterial", name: "เปลี่ยนวัตถุดิบ CHANGE MATERIAL" },
  {
    key: "changeSupplierMaterial",
    name: "เปลี่ยนผู้ส่งมอบวัตถุดิบ CHANGE SUPPLIER MATERIAL"
  },
  { key: "changePlant", name: "เปลี่ยนสถานที่ CHANGE PLANT" }
]

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    backgroundColor: "white",
    color: "black",
    padding: "5px"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  blackGroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: 0,
  fontSize: "16px",
  border: "solid 1px black"
}))

export const TrialToolingPaper: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props

  const displayDivision = {
    eng: filteredData?.selectedDivisions
      ? "ENG" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd1: filteredData?.selectedDivisions
      ? "PD1" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd2: filteredData?.selectedDivisions
      ? "PD2" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd: filteredData?.selectedDivisions
      ? "PD" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pt: filteredData?.selectedDivisions
      ? "PT" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    qa: filteredData?.selectedDivisions
      ? "QA" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    mt: filteredData?.selectedDivisions
      ? "MT" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false
  }

  useEffect(() => {
    setPartNo(filteredData?.partNo)

    setTargetTrialEvent(filteredData?.targetTrialEvent || "")
    setCycleTime(filteredData?.cycleTime || "")
    setCapacity(filteredData?.capacity || "")
    setInternalDefect(filteredData?.internalDefect || "")
    setOperatorSkill(filteredData?.operatorSkill || "")
    setCpk(filteredData?.cpk || "")
    setReworkRatio(filteredData?.reworkRatio || "")

    setSelectedTools(
      filteredData?.selectedTools ? JSON.parse(filteredData.selectedTools) : {}
    )
    setIsOtherSelected(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || false
        : false
    )
    setOtherToolName(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || ""
        : ""
    )
    setToolings(
      filteredData?.toolingData
        ? JSON.parse(filteredData.toolingData)
        : toolingData
    )

    setQuantity(filteredData?.quantity || "")

    setPJPlanDate(
      filteredData?.pJPlanDate
        ? moment(filteredData.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    )
    setPJStartTime(parseTimeStringToDate(filteredData?.pJStartTime))
    setPJEndTime(parseTimeStringToDate(filteredData?.pJEndTime))
    setPJSignature(filteredData?.pJSignature || "")
    setPJMembersTrial(
      filteredData?.pJMembersTrial
        ? JSON.parse(filteredData.pJMembersTrial)
        : []
    )

    setPLPlanDate(
      filteredData?.pLPlanDate
        ? moment(filteredData.pLPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    )
    setPLStartTime(
      filteredData?.pLStartTime
        ? parseTimeStringToDate(filteredData?.pLStartTime)
        : parseTimeStringToDate(filteredData?.pJStartTime)
    )
    setPLEndTime(
      filteredData?.pLEndTime
        ? parseTimeStringToDate(filteredData?.pLEndTime)
        : parseTimeStringToDate(filteredData?.pJEndTime)
    )
    setPLSignature(filteredData?.pLSignature || "")
    setPLMembersTrial(
      filteredData?.pLMembersTrial
        ? JSON.parse(filteredData.pLMembersTrial)
        : []
    )

    setENGSignature(filteredData?.eNGSignature || "")
    setENGMembersTrial(
      filteredData?.eNGMembersTrial
        ? JSON.parse(filteredData.eNGMembersTrial)
        : []
    )

    setPD1Signature(filteredData?.pD1Signature || "")
    setPD1MembersTrial(
      filteredData?.pD1MembersTrial
        ? JSON.parse(filteredData.pD1MembersTrial)
        : []
    )

    setPD2Signature(filteredData?.pD2Signature || "")
    setPD2MembersTrial(
      filteredData?.pD2MembersTrial
        ? JSON.parse(filteredData.pD2MembersTrial)
        : []
    )

    setPDSignature(filteredData?.pDSignature || "")
    setPDMembersTrial(
      filteredData?.pDMembersTrial
        ? JSON.parse(filteredData.pDMembersTrial)
        : []
    )

    setPTSignature(filteredData?.pTSignature || "")
    setPTMembersTrial(
      filteredData?.pTMembersTrial
        ? JSON.parse(filteredData.pTMembersTrial)
        : []
    )

    setQASignature(filteredData?.qASignature || "")
    setQAMembersTrial(
      filteredData?.qAMembersTrial
        ? JSON.parse(filteredData.qAMembersTrial)
        : []
    )

    setMTSignature(filteredData?.mTSignature || "")
    setMTMembersTrial(
      filteredData?.mTMembersTrial
        ? JSON.parse(filteredData.mTMembersTrial)
        : []
    )

    setFmSignature(filteredData?.fmSignature || "")
    setEngineerHead1Signature(filteredData?.engineerHead1Signature || "")
    setEngineerHead2Signature(filteredData?.engineerHead2Signature || "")

    setFmSignatureTime(
      filteredData?.fmSignatureTime ? filteredData.fmSignatureTime : ""
    )

    setEngineerHead1SignatureTime(
      filteredData?.engineerHead1SignatureTime
        ? filteredData.engineerHead1SignatureTime
        : ""
    )
    setEngineerHead2SignatureTime(
      filteredData?.engineerHead2SignatureTime
        ? filteredData.engineerHead2SignatureTime
        : ""
    )

    setCommentPj(filteredData?.commentPj ? filteredData.commentPj : "")
    setCommentPl(filteredData?.commentPl ? filteredData.commentPl : "")
  }, [filteredData])

  const theme = useTheme()
  const [partName, setPartName] = useState<string>("")
  const [partNo, setPartNo] = useState<string | undefined>("")
  const [customer, setCustomer] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [trialDate, setTrialDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [controlNo, setControlNo] = useState<string>("")

  const [targetTrialEvent, setTargetTrialEvent] = useState<string | undefined>(
    ""
  )
  const [cycleTime, setCycleTime] = useState<number | undefined | string>("")
  const [capacity, setCapacity] = useState<number | undefined | string>("")
  const [internalDefect, setInternalDefect] = useState<
    number | undefined | string
  >("")
  const [operatorSkill, setOperatorSkill] = useState<
    number | undefined | string
  >("")
  const [cpk, setCpk] = useState<number | undefined | string>("")
  const [reworkRatio, setReworkRatio] = useState<number | undefined | string>(
    ""
  )

  const [selectedTools, setSelectedTools] = useState<Record<string, string>>({})

  const { data: getParts } = useQuery(GET_PARTS)
  let partNameList: string[] = []
  let partNoList: string[] = []
  if (getParts) {
    partNoList = Array.from(
      new Set(getParts.parts.map((part: { partNo: string }) => part.partNo))
    )
    partNameList = Array.from(
      new Set(getParts.parts.map((part: { partName: string }) => part.partName))
    )
  }
  const customerQuery = useQuery(GET_CUSTOMERS)
  const customerList = useMemo(
    () => makeCustomerList(customerQuery),
    [customerQuery]
  )

  const [toolingData, setToolingData] = useState<ITooling[]>([
    { die: "", size: "", amount: 0, line: "", machineCode: "" }
  ])
  const handleCarFormSubmitTooling = (data: ITooling[]) => {
    setToolingData(data)
  }

  const [material, setMaterial] = useState<string>("")
  const [plating, setPlating] = useState<string>("")
  const [trialNo, setTrialNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number | undefined | string>("")
  const [jobNo, setJobNo] = useState<string>("")

  const [pJPlanDate, setPJPlanDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )

  const parseTimeStringToDate = (timeString?: string): Date | null => {
    if (!timeString) return null
    const [hours, minutes] = timeString.split(":").map(Number)
    const date = new Date()
    date.setHours(hours, minutes, 0, 0)
    return date
  }

  const [pJStartTime, setPJStartTime] = useState<Date | null>(null)
  const [pJEndTime, setPJEndTime] = useState<Date | null>(null)
  const [pJSignature, setPJSignature] = useState<string>("")
  const [pJMembersTrial, setPJMembersTrial] = useState<string[]>([])

  const [pLPlanDate, setPLPlanDate] = useState<string>(
    moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  )

  const [pLStartTime, setPLStartTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJStartTime)
  )
  const [pLEndTime, setPLEndTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJEndTime)
  )

  const displayTimeToDivision = (
    start?: string,
    end?: string,
    signature?: string
  ): string => {
    if (!signature) return ""
    return `${start || ""} - ${end || ""} น.`
  }

  const [pLSignature, setPLSignature] = useState<string>("")
  const [pLMembersTrial, setPLMembersTrial] = useState<string[]>([])

  const [eNGSignature, setENGSignature] = useState<string>("")
  const [eNGMembersTrial, setENGMembersTrial] = useState<string[]>([])

  const [pD1Signature, setPD1Signature] = useState<string>("")
  const [pD1MembersTrial, setPD1MembersTrial] = useState<string[]>([])

  const [pD2Signature, setPD2Signature] = useState<string>("")
  const [pD2MembersTrial, setPD2MembersTrial] = useState<string[]>([])

  const [pDSignature, setPDSignature] = useState<string>("")
  const [pDMembersTrial, setPDMembersTrial] = useState<string[]>([])

  const [pTSignature, setPTSignature] = useState<string>("")
  const [pTMembersTrial, setPTMembersTrial] = useState<string[]>([])

  const [qASignature, setQASignature] = useState<string>("")
  const [qAMembersTrial, setQAMembersTrial] = useState<string[]>([])

  const [mTSignature, setMTSignature] = useState<string>("")
  const [mTMembersTrial, setMTMembersTrial] = useState<string[]>([])

  const { data: getEmployee } = useQuery(GET_EMPLOYEE)
  const listEmployees: string[] =
    Array.from(
      new Set(
        getEmployee?.employee.map(
          ({ firstName }: { firstName: string }) => firstName
        )
      )
    ) || []

  const [fmSignature, setFmSignature] = useState<string>("")
  const [engineerHead1Signature, setEngineerHead1Signature] =
    useState<string>("")
  const [engineerHead2Signature, setEngineerHead2Signature] =
    useState<string>("")

  const [fmSignatureTime, setFmSignatureTime] = useState<string>("")
  const [engineerHead1SignatureTime, setEngineerHead1SignatureTime] =
    useState<string>("")
  const [engineerHead2SignatureTime, setEngineerHead2SignatureTime] =
    useState<string>("")

  const checkMemberInput = async () => {
    if (eNGSignature && eNGMembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial ENG", "warning")
      return false
    } else if (pD1Signature && pD1MembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial PD1", "warning")
      return false
    } else if (pD2Signature && pD2MembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial PD2", "warning")
      return false
    } else if (pDSignature && pDMembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial PD ", "warning")
      return false
    } else if (pTSignature && pTMembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial PT", "warning")
      return false
    } else if (qASignature && qAMembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial QA", "warning")
      return false
    } else if (mTSignature && mTMembersTrial.length < 1) {
      swal("Error", "กรุณาลงชื่อ Member Trial MT", "warning")
      return false
    }

    return true
  }

  function checkEditedMember(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false
    }

    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false
      }
    }

    return true
  }

  const checkPlannerEditTimeSignatureAndMember = async () => {
    if (
      pLPlanDate !== filteredData?.pLPlanDate ||
      moment(pLStartTime).format("HH:mm") !== filteredData?.pLStartTime ||
      moment(pLEndTime).format("HH:mm") !== filteredData?.pLEndTime ||
      pLSignature !== filteredData?.pLSignature ||
      !checkEditedMember(
        pLMembersTrial,
        JSON.parse(filteredData.pLMembersTrial || "{}")
      )
    ) {
      if (
        filteredData?.engineerHead1Signature ||
        filteredData?.engineerHead2Signature
      ) {
        swal(
          "Error",
          "ไม่สามารถแก้ไขเวลา, ลายเซ็น หรือ สมาชิกได้ ได้เนื่องจากทางหัวหน้า Project Engineer Approval เอกสารแล้ว",
          "warning"
        )
        return false
      }
    }
    return true
  }

  const checkDivisionEditSignatureAndMemberAfterFmSign = async () => {
    if (filteredData?.fmSignature) {
      if (
        engineerHead1Signature !== filteredData?.engineerHead1Signature ||
        engineerHead2Signature !== filteredData?.engineerHead2Signature ||
        eNGSignature !== filteredData?.eNGSignature ||
        !checkEditedMember(
          eNGMembersTrial,
          JSON.parse(filteredData.eNGMembersTrial || "{}")
        ) ||
        pD1Signature !== filteredData?.pD1Signature ||
        !checkEditedMember(
          pD1MembersTrial,
          JSON.parse(filteredData.pD1MembersTrial || "{}")
        ) ||
        pD2Signature !== filteredData?.pD2Signature ||
        !checkEditedMember(
          pD2MembersTrial,
          JSON.parse(filteredData.pD2MembersTrial || "{}")
        ) ||
        pDSignature !== filteredData?.pDSignature ||
        !checkEditedMember(
          pDMembersTrial,
          JSON.parse(filteredData.pDMembersTrial || "{}")
        ) ||
        pTSignature !== filteredData?.pTSignature ||
        !checkEditedMember(
          pTMembersTrial,
          JSON.parse(filteredData.pTMembersTrial || "{}")
        ) ||
        qASignature !== filteredData?.qASignature ||
        !checkEditedMember(
          qAMembersTrial,
          JSON.parse(filteredData.qAMembersTrial || "{}")
        ) ||
        mTSignature !== filteredData?.mTSignature ||
        !checkEditedMember(
          mTMembersTrial,
          JSON.parse(filteredData.mTMembersTrial || "{}")
        )
      ) {
        swal(
          "Error",
          "เนื่องจาก FM Approved แล้ว, ไม่สามารถแก้ไขลายเซ็นต์หรือสมาชิกได้",
          "warning"
        )
        return false
      }
    }
    return true
  }

  const [
    updateTrialToolingSheetApproval,
    { loading: loadingUpdateTrialToolingSheetApproval }
  ] = useMutation(TRIAL_TOOLING_SHEET_UPDATE_APPROVAL)

  const handleUpdate = async () => {
    if (!(await checkMemberInput())) return
    if (!(await checkPlannerEditTimeSignatureAndMember())) return
    if (!(await checkDivisionEditSignatureAndMemberAfterFmSign())) return

    try {
      await updateTrialToolingSheetApproval({
        variables: {
          inputTrialToolingApproval: {
            trialId: filteredData?.trialId,
            pLPlanDate,
            pLStartTime: moment(pLStartTime).format("HH:mm"),
            pLEndTime: moment(pLEndTime).format("HH:mm"),
            pLSignature,
            pLMembersTrial: JSON.stringify(pLMembersTrial),
            engineerHead1Signature,
            engineerHead1SignatureTime: filteredData?.engineerHead1Signature
              ? engineerHead1Signature === filteredData?.engineerHead1Signature
                ? filteredData.engineerHead1SignatureTime
                : engineerHead1SignatureTime
              : engineerHead1Signature
              ? engineerHead1SignatureTime
              : "",
            engineerHead2Signature,
            engineerHead2SignatureTime: filteredData?.engineerHead2Signature
              ? engineerHead2Signature === filteredData?.engineerHead2Signature
                ? filteredData.engineerHead2SignatureTime
                : engineerHead2SignatureTime
              : engineerHead2Signature
              ? engineerHead2SignatureTime
              : "",
            eNGSignature,
            eNGMembersTrial: JSON.stringify(eNGMembersTrial),
            pD1Signature,
            pD1MembersTrial: JSON.stringify(pD1MembersTrial),
            pD2Signature,
            pD2MembersTrial: JSON.stringify(pD2MembersTrial),
            pDSignature,
            pDMembersTrial: JSON.stringify(pDMembersTrial),
            pTSignature,
            pTMembersTrial: JSON.stringify(pTMembersTrial),
            qASignature,
            qAMembersTrial: JSON.stringify(qAMembersTrial),
            mTSignature,
            mTMembersTrial: JSON.stringify(mTMembersTrial),
            commentPj,
            commentPl,
            updatedAt: filteredData?.updatedAt,
            updatedBy: user.username,
            fromPage: "Engineer > Trial Tooling Page"
          }
        }
      })
      swal("Success", "บันทึกข้อมูลเรียบร้อย", "success").then(() => {
        window.location.reload()
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const checkUserRoles = (
    userRole: string,
    rolesToCheck: string[]
  ): boolean => {
    const rolesArray = userRole.split(",")

    return rolesToCheck.every((role) => rolesArray.includes(role))
  }

  const userRole = {
    pj:
      (user.position === "Manager" ||
        user.position === "Asst.Mgr" ||
        user.position === "Supervisor" ||
        user.position === "Leader") &&
      checkUserRoles(user.role, ["7", "19"]),
    pl: checkUserRoles(user.role, ["99", "3"]),
    eng:
      (user.position === "Manager" || user.position === "Asst.Mgr") &&
      checkUserRoles(user.role, ["7"]),
    pd1: user.factory === "1" && checkUserRoles(user.role, ["99", "4"]),
    pd2: user.factory === "2" && checkUserRoles(user.role, ["99", "4"]),
    pd:
      (user.position === "Manager" || user.position === "Asst.Mgr") &&
      checkUserRoles(user.role, ["4"]),
    pt:
      (user.position === "Manager" || user.position === "Asst.Mgr") &&
      checkUserRoles(user.role, ["15"]),
    qa: checkUserRoles(user.role, ["99", "6"]),
    mt:
      (user.position === "Manager" || user.position === "Asst.Mgr") &&
      checkUserRoles(user.role, ["12"]),
    leader1: user.position === "Manager" && checkUserRoles(user.role, ["7"]),
    leader2:
      (user.position === "Manager" || user.position === "Asst.Mgr") &&
      checkUserRoles(user.role, ["7"]),
    fm: user.position === "GM"
  }

  const disableTextFieldIfNoPLSignature = {
    pl: !userRole.pl || !pJSignature,
    eng:
      !userRole.eng ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    pd1:
      !userRole.pd1 ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    pd2:
      !userRole.pd2 ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    pd:
      !userRole.pd ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    pt:
      !userRole.pt ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    qa:
      !userRole.qa ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    mt:
      !userRole.mt ||
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature,
    leader1: !pLSignature || !engineerHead2Signature || !userRole.leader1,
    leader2: !pLSignature || !userRole.leader2,
    fm:
      !pLSignature ||
      !engineerHead1Signature ||
      !engineerHead2Signature ||
      !userRole.fm
  }

  const [otherToolName, setOtherToolName] = useState<string>("")
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false)

  const handleToolChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    name: string
  ) => {
    const { checked } = event.target
    setSelectedTools((prev) => {
      const updated = { ...prev }
      if (checked) {
        updated[key] = name
      } else {
        delete updated[key]
      }
      setSelectedTools(updated)
      return updated
    })
  }

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setIsOtherSelected(checked)

    if (!checked) {
      setOtherToolName("")
      setSelectedTools((prev) => {
        const updated = { ...prev }
        delete updated["other"]
        setSelectedTools(updated)
        return updated
      })
    }
  }

  const [toolings, setToolings] = useState<ITooling[]>(toolingData)

  const { data: getMachine } = useQuery(GET_MACHINES)
  const machineQuery: IMachine[] = getMachine?.machines || []

  const machineList = Array.from(
    new Set(machineQuery.map((machine: IMachine) => machine.code))
  )

  const getLineOptions = (code: string | number) => {
    const line = machineQuery
      .filter((e) => e.code === code)
      .map((e) => (e.line ? e.line : ""))
    return Array.from(new Set(line))
  }

  const getMachinePowerOptions = (code: string | number) => {
    const machinePower = machineQuery
      .filter((e) => e.code === code)
      .map((e) => (e.machinePower ? e.machinePower : ""))
    return Array.from(new Set(machinePower))
  }

  const handleChange = (
    index: number,
    field: keyof ITooling,
    value: string | number
  ) => {
    const newToolings = [...toolings]
    newToolings[index][field] = value

    setToolings(newToolings)
    handleCarFormSubmitTooling(newToolings)
  }

  const signatureNames = {
    pj: ["พิทยา", "กฤษณะ", "อนุสรณ์"],
    pl: ["ณัฏฐ์", "มนตรี", "อมราวดี"],
    eng: ["นรินทร"],
    pd1: ["อนุชา", "ไพฑูรย์"],
    pd2: ["อนุชา", "ไพฑูรย์"],
    pd: ["อนุชา", "ไพฑูรย์"],
    pt: listEmployees,
    qa: ["ไชยศ", "นริศรา"],
    mt: listEmployees,
    leader1: ["ภควัต"],
    leader2: ["ภควัต", "พิทยา"],
    fm:
      Array.from(
        new Set(
          getEmployee?.employee
            .filter(({ position }: { position: string }) => {
              return position === "FM"
            })
            .map(({ firstName }: { firstName: string }) => firstName)
        )
      ) || []
  }
  const memberTrialNames = {
    pj: listEmployees,
    pl: listEmployees,
    eng: ["สำรวย"],
    pd1: [
      "จรัญ",
      "ศุภชัย",
      "สุรศักดิ์",
      "วีรชัย",
      "สาหร่าย",
      "เกรียงไกร",
      "วิชิต",
      "ธนายุทธ"
    ],
    pd2: [
      "จรัญ",
      "ศุภชัย",
      "สุรศักดิ์",
      "วีรชัย",
      "สาหร่าย",
      "เกรียงไกร",
      "วิชิต",
      "ธนายุทธ"
    ],
    pd: [
      "จรัญ",
      "ศุภชัย",
      "สุรศักดิ์",
      "วีรชัย",
      "สาหร่าย",
      "เกรียงไกร",
      "วิชิต",
      "ธนายุทธ"
    ],
    pt: listEmployees,
    qa: ["ธวัชชัย", "สิทธิชัย", "กฤษณา", "สุนิสา", "วิไลวรรณ"],
    mt: listEmployees
  }

  const [commentPj, setCommentPj] = useState<string>("")
  const [commentPl, setCommentPl] = useState<string>("")

  return (
    <ThemeProvider theme={theme}>
      <div className="repair-print-head">
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={miniLogo} alt="BAE logo" width={"80px"} />
                  <Typography variant="h1" sx={{ fontSize: "20px" }}>
                    Brother Auto Parts & Engineering Co.,Ltd.
                  </Typography>
                </Box>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={1}>
                <Typography variant="h2" sx={{ fontSize: "15px" }}>
                  TRIAL TOOLING SHEET (ใบขอทดลองผลิต)
                </Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={4}></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <Grid container>
                  <Grid
                    container
                    item
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part Name: (ชื่อชิ้นงาน)</Typography>
                        <Autocomplete
                          disabled
                          sx={{ width: 300 }}
                          value={filteredData?.partName || partName}
                          options={partNameList}
                          onChange={(e, value) => setPartName(value as string)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part No: (เลขที่ชิ้นงาน)</Typography>
                        <Autocomplete
                          disabled
                          sx={{ width: 300 }}
                          value={partNo}
                          options={partNoList}
                          onChange={(e, value) => setPartNo(value as string)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Customer:(ลูกค้า)</Typography>
                        <Autocomplete
                          disabled
                          sx={{ width: 300 }}
                          value={filteredData?.customer || customer}
                          options={customerList}
                          onChange={(e, value) => setCustomer(value as string)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Plant:(สถานที่ขอทดลอง)</Typography>
                        <Autocomplete
                          disabled
                          sx={{ width: 300 }}
                          value={filteredData?.factory || factory}
                          options={["โรง 1", "โรง 2"]}
                          onChange={(e, value) => setFactory(value as string)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Date/วันที่ออกเอกสาร</Typography>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DatePicker
                            disabled
                            openTo="day"
                            views={["year", "month", "day"]}
                            inputFormat="DD/MM/YYYY"
                            aria-label="date-from"
                            value={
                              filteredData?.trialDate
                                ? moment(
                                    filteredData.trialDate,
                                    "YYYY-MM-DD"
                                  ).toDate()
                                : trialDate
                            }
                            onChange={(newStartDate) => {
                              setTrialDate(
                                moment(newStartDate).format("YYYY-MM-DD")
                              )
                            }}
                            renderInput={(params) => (
                              <ThemedTextField
                                {...params}
                                variant="standard"
                                type="date"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                sx={{
                                  width: 300,
                                  input: {
                                    color: "black",
                                    textAlign: "center"
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "#666666"
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "#666666"
                                  },
                                  "& .MuiInput-underline:hover:before": {
                                    borderBottomColor: "#666666"
                                  }
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Control No./เลขที่เอกสาร</Typography>
                        <TextField
                          disabled
                          variant="standard"
                          type="text"
                          onChange={(e) => setControlNo(e.target.value)}
                          value={filteredData?.controlNo || controlNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                    </Grid>
                    <Grid item>
                      <img
                        src={getImagePng(`images/parts/${partNo}`)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = bankImage
                        }}
                        width={200}
                        height={200}
                        alt=""
                      ></img>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        p: "20px"
                      }}
                    >
                      <Grid item>
                        <Typography>TARGET TRIAL</Typography>
                      </Grid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Event</Typography>
                        <TextField
                          disabled
                          fullWidth
                          type="text"
                          variant="standard"
                          onChange={(e) => setTargetTrialEvent(e.target.value)}
                          value={targetTrialEvent}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>1. Cycle time :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setCycleTime(+e.target.value)}
                          value={cycleTime}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(Sec.)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>2. Capacity :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setCapacity(+e.target.value)}
                          value={capacity}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>3. Internal defect :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setInternalDefect(+e.target.value)}
                          value={internalDefect}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>4. Operator Skill :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setOperatorSkill(+e.target.value)}
                          value={operatorSkill}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>5. Cpk :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setCpk(+e.target.value)}
                          value={cpk}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>6. Rework Ratio :</Typography>
                        <TextField
                          disabled
                          type="number"
                          variant="standard"
                          onChange={(e) => setReworkRatio(+e.target.value)}
                          value={reworkRatio}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ mt: "50px" }}>
                    <Box sx={{ padding: 2 }}>
                      <FormGroup>
                        <Grid container spacing={2}>
                          {toolNameData.map((tool) => (
                            <Grid
                              item
                              xs={4}
                              key={tool.key}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start"
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled
                                    onChange={(e) =>
                                      handleToolChange(e, tool.key, tool.name)
                                    }
                                    checked={!!selectedTools[tool.key]}
                                  />
                                }
                                label={tool.name}
                              />
                            </Grid>
                          ))}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start"
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={isOtherSelected}
                                  onChange={handleOtherChange}
                                />
                              }
                              label="Other"
                            />
                            <TextField
                              fullWidth
                              variant="standard"
                              disabled={!isOtherSelected}
                              value={otherToolName}
                              sx={{
                                input: { color: "black", textAlign: "center" },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      pt: "50px",

                      borderTop: "2px solid #000"
                    }}
                    xs={12}
                  >
                    <Grid container direction="column" spacing={3}>
                      {toolings.map((tooling, index) => (
                        <Grid item key={index}>
                          <PMDocumentGrid container spacing={2}>
                            <Typography>{index + 1}. Process :</Typography>
                            <TextField
                              disabled
                              variant="standard"
                              onChange={(e) =>
                                handleChange(index, "die", e.target.value || "")
                              }
                              value={toolings[index].die}
                              sx={{
                                width: "200px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>M/C</Typography>

                            <Typography>Size(ขนาดเครื่องจักร)</Typography>
                            <Autocomplete
                              disabled
                              sx={{ width: 150 }}
                              options={getMachinePowerOptions(
                                tooling.machineCode
                              )}
                              value={toolings[index].size}
                              onChange={(event, newValue) =>
                                handleChange(index, "size", newValue || "")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                            <Typography sx={{ pr: "20px" }}>
                              Tons(ตัน)
                            </Typography>

                            <Typography>จำนวน</Typography>
                            <TextField
                              disabled
                              type="number"
                              variant="standard"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "amount",
                                  +e.target.value || 0
                                )
                              }
                              value={toolings[index].amount}
                              sx={{
                                width: "50px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>เครื่อง</Typography>

                            <Typography>Line</Typography>
                            <Autocomplete
                              disabled
                              sx={{ width: 150 }}
                              options={getLineOptions(tooling.machineCode)}
                              value={toolings[index].line}
                              onChange={(event, newValue) =>
                                handleChange(index, "line", newValue || "")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                            <Typography sx={{ pr: "20px" }}>No.</Typography>

                            <Typography>M/C (เลขที่เครื่องจักร)</Typography>
                            <Autocomplete
                              disabled
                              sx={{ width: 200 }}
                              options={machineList}
                              value={toolings[index].machineCode}
                              onChange={(event, newValue) =>
                                handleChange(
                                  index,
                                  "machineCode",
                                  newValue || ""
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                          </PMDocumentGrid>
                        </Grid>
                      ))}

                      <Grid
                        container
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>MATERIAL (วัตถุดิบ) </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="standard"
                            onChange={(e) => setMaterial(e.target.value)}
                            value={filteredData?.material || material}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>ผิวชุบ Plating Coating </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="standard"
                            onChange={(e) => setPlating(e.target.value)}
                            value={filteredData?.plating || plating}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>

                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Trial No. (เลขที่ทดลอง) </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="standard"
                            onChange={(e) => setTrialNo(e.target.value)}
                            value={filteredData?.trialNo || trialNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Q'ty (จำนวนชิ้น) </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="standard"
                            onChange={(e) => setQuantity(+e.target.value)}
                            value={quantity}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                          <Typography>Pcs. (ชิ้น)</Typography>
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>JOB No.(เลขที่สั่งงาน) </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="standard"
                            onChange={(e) => setJobNo(e.target.value)}
                            value={filteredData?.jobNo || jobNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      mt: "50px"
                    }}
                    xs={12}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell rowSpan={2} colSpan={2}>
                            <Typography>ฝ่าย/แผนก</Typography>
                            <Typography>Department/Section Division</Typography>
                          </StyledTableCell>
                          <StyledTableCell colSpan={2}>
                            <Typography>
                              Trial Plan Date(วันที่ทดลอง)
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>Signature</Typography>
                            <Typography>ลงชื่อ</Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>MEMBER TRIAL</Typography>
                            <Typography>รายชื่อผู้ร่วมทดลองการผลิต</Typography>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>Plan Date(วัน)</StyledTableCell>
                          <StyledTableCell sx={{ minWidth: "150px" }}>
                            Plan Time(เวลา)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>วิศวกรโครงการ</StyledTableCell>
                          <StyledTableCell>PJ</StyledTableCell>
                          <StyledTableCell>
                            <FormTextField
                              disabled={!userRole.pj}
                              type="date"
                              variant="standard"
                              fullWidth
                              value={moment(pJPlanDate).format("YYYY-MM-DD")}
                              onChange={(e) => setPJPlanDate(e.target.value)}
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                "& .MuiInputBase-input": {
                                  textAlign: "center"
                                }
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <TimePicker
                                disabled={!userRole.pj}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pJStartTime}
                                onChange={(newSelectTime) => {
                                  setPJStartTime(newSelectTime)
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" - "}
                              <TimePicker
                                disabled={!userRole.pj}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pJEndTime}
                                onChange={(newSelectTime) => {
                                  setPJEndTime(newSelectTime)
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" น. "}
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pj}
                              sx={{ width: 200 }}
                              options={signatureNames.pj}
                              value={pJSignature}
                              onChange={(event, newValue) =>
                                setPJSignature(newValue as string)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pj}
                              multiple
                              freeSolo
                              options={memberTrialNames.pj}
                              value={pJMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPJMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วางแผน</StyledTableCell>
                          <StyledTableCell>PL</StyledTableCell>
                          <StyledTableCell>
                            <FormTextField
                              disabled={disableTextFieldIfNoPLSignature.pl}
                              type="date"
                              variant="standard"
                              fullWidth
                              value={moment(pLPlanDate).format("YYYY-MM-DD")}
                              onChange={(e) => setPLPlanDate(e.target.value)}
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                "& .MuiInputBase-input": {
                                  textAlign: "center"
                                }
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <TimePicker
                                disabled={disableTextFieldIfNoPLSignature.pl}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pLStartTime}
                                onChange={(newSelectTime) => {
                                  setPLStartTime(newSelectTime)
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" - "}
                              <TimePicker
                                disabled={disableTextFieldIfNoPLSignature.pl}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pLEndTime}
                                onChange={(newSelectTime) => {
                                  setPLEndTime(newSelectTime)
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" น. "}
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={disableTextFieldIfNoPLSignature.pl}
                              sx={{ width: 200 }}
                              options={signatureNames.pl}
                              value={pLSignature}
                              onChange={(event, newValue) =>
                                setPLSignature(newValue as string)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={disableTextFieldIfNoPLSignature.pl}
                              multiple
                              freeSolo
                              options={memberTrialNames.pl}
                              value={pLMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPLMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        {displayDivision.eng ? (
                          <StyledTableRow>
                            <StyledTableCell>วิศวกรรม</StyledTableCell>
                            <StyledTableCell>ENG</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.eng}
                                sx={{ width: 200 }}
                                options={signatureNames.eng}
                                value={eNGSignature}
                                onChange={(event, newValue) =>
                                  setENGSignature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.eng}
                                multiple
                                freeSolo
                                options={memberTrialNames.eng}
                                value={eNGMembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setENGMembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd1 ? (
                          <StyledTableRow>
                            <StyledTableCell>ผลิตโรง 1</StyledTableCell>
                            <StyledTableCell>PD 1</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd1}
                                sx={{ width: 200 }}
                                options={signatureNames.pd1}
                                value={pD1Signature}
                                onChange={(event, newValue) =>
                                  setPD1Signature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd1}
                                multiple
                                freeSolo
                                options={memberTrialNames.pd1}
                                value={pD1MembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setPD1MembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd2 ? (
                          <StyledTableRow>
                            <StyledTableCell>ผลิตโรง 2</StyledTableCell>
                            <StyledTableCell>PD 2</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd2}
                                sx={{ width: 200 }}
                                options={signatureNames.pd2}
                                value={pD2Signature}
                                onChange={(event, newValue) =>
                                  setPD2Signature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd2}
                                multiple
                                freeSolo
                                options={memberTrialNames.pd2}
                                value={pD2MembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setPD2MembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd ? (
                          <StyledTableRow>
                            <StyledTableCell>
                              ประกอบ Spot/Wedling Assemble
                            </StyledTableCell>
                            <StyledTableCell>PD</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd}
                                sx={{ width: 200 }}
                                options={signatureNames.pd}
                                value={pDSignature}
                                onChange={(event, newValue) =>
                                  setPDSignature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pd}
                                multiple
                                freeSolo
                                options={memberTrialNames.pd}
                                value={pDMembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setPDMembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pt ? (
                          <StyledTableRow>
                            <StyledTableCell>ชุบโลหะ</StyledTableCell>
                            <StyledTableCell>PT</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pt}
                                sx={{ width: 200 }}
                                options={signatureNames.pt}
                                value={pTSignature}
                                onChange={(event, newValue) =>
                                  setPTSignature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.pt}
                                multiple
                                freeSolo
                                options={memberTrialNames.pt}
                                value={pTMembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setPTMembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.qa ? (
                          <StyledTableRow>
                            <StyledTableCell>ประกันคุณภาพ</StyledTableCell>
                            <StyledTableCell>QA</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.qa}
                                sx={{ width: 200 }}
                                options={signatureNames.qa}
                                value={qASignature}
                                onChange={(event, newValue) =>
                                  setQASignature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.qa}
                                multiple
                                freeSolo
                                options={memberTrialNames.qa}
                                value={qAMembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setQAMembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.mt ? (
                          <StyledTableRow>
                            <StyledTableCell>ซ่อมบำรุง</StyledTableCell>
                            <StyledTableCell>MT</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature ? pLPlanDate : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {displayTimeToDivision(
                                filteredData?.pLStartTime,
                                filteredData?.pLEndTime,
                                pLSignature
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.mt}
                                sx={{ width: 200 }}
                                options={signatureNames.mt}
                                value={mTSignature}
                                onChange={(event, newValue) =>
                                  setMTSignature(newValue as string)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Autocomplete
                                disabled={disableTextFieldIfNoPLSignature.mt}
                                multiple
                                freeSolo
                                options={memberTrialNames.mt}
                                value={mTMembersTrial}
                                sx={{
                                  width: "320px",
                                  display: "inline-flex"
                                }}
                                onChange={(e, newValue) =>
                                  setMTMembersTrial(newValue)
                                }
                                renderTags={autocompleteRenderTags}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      input: {
                                        color: "black"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell
                rowSpan={4}
                sx={{ width: 650, p: "0 20px 0 20px" }}
              >
                <Typography sx={{ fontSize: "20px" }}>
                  PJ (ข้อเสนอแนะ)
                </Typography>

                <TextField
                  disabled={!userRole.pj}
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  rows={5}
                  onChange={(e) => setCommentPj(e.target.value)}
                  value={commentPj}
                />
                <Typography sx={{ fontSize: "20px" }}>
                  PL (ข้อเสนอแนะ)
                </Typography>
                <TextField
                  disabled={!userRole.pl}
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  rows={5}
                  onChange={(e) => setCommentPl(e.target.value)}
                  value={commentPl}
                />
              </StyledTableCell>
              <StyledTableCell>
                Approve by
                <br />
                (ผู้อนุมัติ)
              </StyledTableCell>
              <StyledTableCell>
                Approve by
                <br />
                (ผู้อนุมัติ)
              </StyledTableCell>
              <StyledTableCell>
                Check by
                <br />
                (ผู้ตรวจสอบ)
              </StyledTableCell>
              <StyledTableCell>
                Prepare by
                <br />
                (ผู้จัดทำ)
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ height: 150 }}>
              <StyledTableCell>
                <Autocomplete
                  disabled
                  options={listEmployees}
                  value={fmSignature}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Autocomplete
                  disabled={disableTextFieldIfNoPLSignature.leader1}
                  options={signatureNames.leader1}
                  value={engineerHead1Signature}
                  onChange={(event, newValue) => {
                    setEngineerHead1Signature(newValue as string)
                    setEngineerHead1SignatureTime(
                      moment(new Date()).format("YYYY-MM-DD")
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Autocomplete
                  disabled={disableTextFieldIfNoPLSignature.leader2}
                  options={signatureNames.leader2}
                  value={engineerHead2Signature}
                  onChange={(event, newValue) => {
                    setEngineerHead2Signature(newValue as string)
                    setEngineerHead2SignatureTime(
                      moment(new Date()).format("YYYY-MM-DD")
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>{pJSignature}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>{fmSignatureTime}</StyledTableCell>
              <StyledTableCell>{engineerHead1SignatureTime}</StyledTableCell>
              <StyledTableCell>{engineerHead2SignatureTime}</StyledTableCell>
              <StyledTableCell>
                {filteredData?.pJSignature ? filteredData?.pJSignatureTime : ""}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>FM ผู้จัดการโรงงาน</StyledTableCell>
              <StyledTableCell colSpan={3}>
                Reqeust by (ผู้ร้องขอ) Project Engineer
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <ThemedLoadingButton
          loading={loadingUpdateTrialToolingSheetApproval}
          variant="contained"
          sx={{ width: "200px", fontWeight: 600, fontSize: "20px", mt: "50px" }}
          onClick={handleUpdate}
        >
          Update ข้อมูล
        </ThemedLoadingButton>
        <br />
      </div>
    </ThemeProvider>
  )
}

export interface ITrialToolingData {
  [key: string]: number | string | undefined
  trialId: number
  recordNo: number
  partName?: string
  partNo?: string
  customer?: string
  factory?: string
  trialDate?: string
  targetTrialEvent?: string
  controlNo?: string
  revisionNo?: string
  cycleTime?: number
  capacity?: number
  internalDefect?: number
  operatorSkill?: number
  cpk?: number
  reworkRatio?: number
  selectedTools?: string
  toolingData?: string
  material?: string
  plating?: string
  trialNo?: string
  quantity?: number
  jobNo?: string
  pJPlanDate?: string
  pJStartTime?: string
  pJEndTime?: string
  pJSignature?: string
  pJSignatureTime?: string
  pJMembersTrial?: string
  pLPlanDate?: string
  pLStartTime?: string
  pLEndTime?: string
  pLSignature?: string
  pLMembersTrial?: string
  eNGSignature?: string
  eNGMembersTrial?: string
  pD1Signature?: string
  pD1MembersTrial?: string
  pD2Signature?: string
  pD2MembersTrial?: string
  pDSignature?: string
  pDMembersTrial?: string
  pTSignature?: string
  pTMembersTrial?: string
  qASignature?: string
  qAMembersTrial?: string
  mTSignature?: string
  mTMembersTrial?: string
  fmSignature?: string
  engineerHead1Signature?: string
  engineerHead2Signature?: string
  fmSignatureTime?: string
  engineerHead1SignatureTime?: string
  engineerHead2SignatureTime?: string
  updatedAt?: string
  selectedDivisions?: string
  commentPj?: string
  commentPl?: string
  fileName?: string
}

interface IProp {
  filteredData?: ITrialToolingData
}
const TrialToolingSheetPlanningApproval: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props

  return (
    <>
      <Typography
        variant="h1"
        sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}
      >
        สำหรับแผนก Planning ตรวจสอบและ Approve
      </Typography>
      <TrialToolingPaper filteredData={filteredData} />
    </>
  )
}

export default TrialToolingSheetPlanningApproval
