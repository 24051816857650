import { ITrialToolingData } from "./TrialToolingSheetPlanningApproval"

import {
    Grid,
    Typography,
    useTheme
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { TRIAL_TOOLING_SHEET_UPLOAD_SUMMARY_DOCUMENT } from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import UploadFileBox, { SelectedFileBox } from "../../../common/Resources/UploadFileBox"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { FileUpload } from "@mui/icons-material"
import { DOWNLOAD_TRIAL_TOOLING_SUMMERY, GET_TRIAL_TOOLING_SHEET } from "../../../common/Query/engineerQuery"
import DialogShowPdf from "../../Document/component/DialogShowPdf"
import { checkRole } from "../../../config/routes"


const TrialToolingPaper: React.FC<IProp> = (props: IProp) => {
    const { filteredData } = props
    const theme = useTheme()

    useEffect(() => {
        setTrialId(filteredData?.trialId || undefined)

    }, [filteredData])




    const [trialId, setTrialId] = useState<number | undefined>(undefined)
    const [error, setError] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [url, setUrl] = useState<string>("")
    const [downloadTrialToolingSummery] = useLazyQuery(DOWNLOAD_TRIAL_TOOLING_SUMMERY)

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        
        if (file && file.type !== "application/pdf") {
            swal("Invalid File", "ไฟล์ pdf เท่านั้น", "warning");
            return;
        }

        if (!filteredData?.controlNo) {
            return swal("ERROR", `ไม่พบ control number`, "warning")
        }
        const replaceUnderScore = filteredData?.controlNo?.replace(/ /g, "_");

        if (file) {
            const renamedFile = new File([file], replaceUnderScore + file.name.substring(file.name.lastIndexOf('.')), { type: file.type });
            setSelectedFile(renamedFile);
        } else {
            setSelectedFile(null);
        }
    };

    const [trialToolingSheetUploadSummaryDocument, { loading: uploadLoading }] = useMutation(
        TRIAL_TOOLING_SHEET_UPLOAD_SUMMARY_DOCUMENT,
        {
            refetchQueries: [GET_TRIAL_TOOLING_SHEET],
            awaitRefetchQueries: true
        }
    )

    const toggleUpload = async () => {
        if (!trialId) {
            setError(true)
            return swal("ERROR", "Trial ID ไม่ถูกต้อง", "error")
        }
        if (!selectedFile) {
            setError(true)
            return swal("ERROR", "กรุณาเลือก File", "error")
        }
        try {

            const { data: dataUrl } = await trialToolingSheetUploadSummaryDocument({
                variables: {
                    trialToolingId: trialId,
                    fileName: selectedFile?.name,
                    contentType: selectedFile?.type,
                    uploaded: false
                }
            })

            const presignedUrl = dataUrl.trialToolingSheetUploadSummaryDocument

            const response = await fetch(presignedUrl, {
                method: "PUT",
                body: selectedFile,
                headers: {
                    "Content-Type": selectedFile?.type ?? ""
                }
            })

            if (response.status === 200) {
                const data = {
                    trialToolingId: trialId,
                    uploaded: true
                }
                await trialToolingSheetUploadSummaryDocument({
                    variables: data
                })
                swal("Success", "Uploaded ไฟล์ใหม่แล้ว", "success")
            }

        } catch (error) {
            swal("ERROR", `${(error as Error).message}`, "warning")
        }
    }



    const handleDownload = async (e: { preventDefault: () => void }) => {
        e.preventDefault()
        try {
            const result = await downloadTrialToolingSummery({
                variables: {
                    trialId: trialId,
                }
            })

            window.open(result.data.downloadTrialToolingSummery
                .url)
            swal("Success", "", "success")
        } catch (error) {
            swal("ERROR", `${(error as Error).message}`, "warning")
        }
    }

    const handleShowPdf = async (e: { preventDefault: () => void }) => {
        e.preventDefault()
        try {
            const result = await downloadTrialToolingSummery({
                variables: {
                    trialId: trialId,
                }
            })
            setUrl(result.data.downloadTrialToolingSummery
                .url)
            setOpen(true)
        } catch (error) {
            swal("ERROR", `${(error as Error).message}`, "warning")
        }
    }




    return (

        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" component="span">กำลังทำรายการ Document No ที่: </Typography> {filteredData?.controlNo}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="span">File เอกสารที่เคย Upload (อัพโหลดไฟล์ใหม่จะทับของเก่า): </Typography> {
                    filteredData?.fileName ?
                        <Typography component="span" sx={{ color: "green" }}>{filteredData?.fileName}</Typography>
                        :
                        <Typography component="span" sx={{ color: "red" }}>ยังไม่เคย Upload</Typography>

                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <UploadFileBox
                    changeHandler={changeHandler}
                    error={error}
                    selectedFile={selectedFile}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectedFileBox
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    createLoading={uploadLoading}
                />
            </Grid>
            <Grid item xs={4} marginTop={5}>
                <ThemedLoadingButton
                    disabled={!((checkRole(["19"]) || checkRole(["7"])) && selectedFile) || !(checkRole(["99"]) && selectedFile)}
                    loading={uploadLoading}
                    variant="contained"
                    sx={{ mt: 1 }}
                    style={{
                        backgroundColor: !selectedFile ? "gray" : theme.palette.primary.main,
                        color: "white"
                    }}
                    onClick={toggleUpload}
                    endIcon={<FileUpload />}
                >
                    Upload New File
                </ThemedLoadingButton>
            </Grid>
            <Grid item xs={4} marginTop={5}>
                <ThemedLoadingButton
                    disabled={!filteredData?.fileName}

                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: !filteredData?.fileName ? "gray" : theme.palette.primary.main }}
                    onClick={handleDownload}
                >
                    Download Previous PDF
                </ThemedLoadingButton>
            </Grid>
            <Grid item xs={4} marginTop={5}>
                <ThemedLoadingButton
                    disabled={!filteredData?.fileName}

                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: !filteredData?.fileName ? "gray" : theme.palette.primary.main }}
                    onClick={handleShowPdf}
                >
                    Show Previous PDF
                </ThemedLoadingButton>
            </Grid>
            <DialogShowPdf url={url} showPdf={open} setShowPdf={setOpen} />

        </Grid>

    )
}

interface IProp {
    filteredData?: ITrialToolingData
}
const TrialToolingSheetDocumentUpload: React.FC<IProp> = (props: IProp) => {
    const { filteredData } = props
    return (
        <>
            <Typography
                variant="h1"
                sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}
            >
                Upload Summary Document
            </Typography>
            <div className="repair-print-head">
                <TrialToolingPaper filteredData={filteredData} />
            </div>
        </>
    )
}

export default TrialToolingSheetDocumentUpload
